// types
import type { UserRole } from '@revolutionprep/types'

// utils
import { hasRoles } from '@revolutionprep/utils'

export default defineNuxtRouteMiddleware((to) => {
  // nuxt app
  const { $actor } = useNuxtApp()

  const allowedRoles = to.meta?.roles as UserRole[]

  /**
   * once we want to allow parents to log in,
   * we will require a backend change to:
   * - add role of 'student' to student
   * - add role of 'parent' to parent
   */

  if (allowedRoles && allowedRoles.length) {
    const actor = $actor.core.actor.value

    if (!hasRoles(allowedRoles, actor?.roles)) {
      throw createError({
        statusCode: 401,
        message: 'You don\'t have the permissions to view this resource.'
      })
    }
  }
})
